<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="!isCopy && params.id ? '编辑模板' : '新建模板'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 模板名称 -->
      <a-form-item
        label="模板名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- 金额类型 -->
      <a-form-item
        label="金额类型"
        name="money_type"
        :rules="[{ required: true }]"
      >
        <a-select
          style="width: 50%;"
          v-model:value="formState.money_type"
          placeholder="请选择"
        >
          <a-select-option
            v-for="item in RechargeTemplateTypes"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 充值类型 -->
      <a-form-item
        label="充值类型"
        name="is_first_charge"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.is_first_charge">
          <a-radio
            v-for="item in TemplateTypes"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 模板样式 -->
      <a-form-item
        label="模板样式"
        name="style_number"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.style_number" @change="changeType">
          <a-radio :value="4">四宫格</a-radio>
          <a-radio :value="6">六宫格</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 组 -->
      <a-form-item
        :label="'第' + (gindex - 0 + 1) + '组'"
        :rules="[{ required: true }]"
        v-for="(group, gindex) in formState.content"
      >
        <div class="data-list">
          <!-- 单条数据：输入框必填，多选框非必填（多选框勾选，后面的输入框必填） -->
          <template v-for="(item, index) in keySetting[group.type]" :key="index">
            <a-form-item
              class="play-item"
              v-if="item.key === 'pay_guide'"
              :style="{ width: (item.width || '100%'), marginRight: (item.marginRight || 0), marginBottom: 0 }"
            >
              <div :class="index === 0 ? 'grow' : ''">
                <!-- 付费强化引导 -->
                <template v-if="item.isModule">
                  <!-- 标题 -->
                  <div class="module-title">{{ item.name }}</div>
                  <!-- 首充引导复充 -->
                  <a-checkbox
                    v-if="formState.is_first_charge != 2 && group.type === 'coin'"
                    v-model:checked="group.item.is_pay_guide"
                    @change="changePayCheck()"
                  >
                    首充引导复充
                    <a-tooltip>
                      <template #title>当用户在该链接下产生首充，支付成功跳转回来时，引导用户进行二次充值。</template>
                      <InfoCircleOutlined />
                    </a-tooltip>
                  </a-checkbox>
                  <!-- 复充面板 -->
                  <div
                    v-if="formState.is_first_charge != 2 && group.type === 'coin'"
                    class="module-panel"
                  >
                    <!-- 复充类型 -->
                    <a-form-item
                      :rules="[{ required: group.item.is_pay_guide, message: '请选择' }]"
                      :name="['content', gindex, 'item', 'pay_guide_type']"
                      style="width: 50%;"
                    >
                      <a-select
                        v-model:value="group.item.pay_guide_type"
                        placeholder="请选择复充类型"
                        @change="changePayCheck()"
                      >
                        <a-select-option :value="1">再次购买同量虚拟币</a-select-option>
                        <a-select-option :value="2">再付一笔解锁全集</a-select-option>
                      </a-select>
                    </a-form-item>
                    <!-- 分割 -->
                    <div style="width: 10px;"></div>
                    <!-- 复充价格 -->
                    <a-form-item
                      :rules="[{ required: group.item.is_pay_guide, validator: (rule, value) => { return validatorPayGuideMoney(rule, value, group) } }]"
                      :name="['content', gindex, 'item', 'pay_guide_money']"
                      style="width: 50%;"
                    >
                      <a-input
                        v-model:value="group.item.pay_guide_money"
                        suffix="价格"
                        placeholder="请输入"
                        @input="handlePayInput($event, group, 'item', 'pay_guide_money')"
                      />
                    </a-form-item>
                  </div>
                </template>
              </div>
            </a-form-item>
            <!-- 其他字段 -->
            <a-form-item
              v-else
              class="play-item"
              :rules="[{ required: (!item.isCheck && (!item.bindCheckKey || group[item.bindCheckKey]) && !item.notRequired), validator: (rule, value) => { return validator(rule, value, group) } }]"
              :name="['content', gindex, item.key]"
              :style="{ width: (item.width || '100%'), marginRight: (item.marginRight || 0) }"
            >
              <div :class="index === 0 ? 'grow' : ''">
                <!-- 输入框 -->
                <a-input
                  :placeholder="item.key === 'description' ? '划线内容请用 [ ] 包裹' : '请输入'"
                  v-model:value="group[item.key]"
                  :suffix="item.name"
                  :maxlength="item.maxlength"
                  @input="handleInput($event, group, item.key, `content.${gindex}.${item.key}`)"
                  v-if="(!item.isCheck && !item.isRadio)"
                />
                <!-- 气泡、角标：勾选框 -->
                <template v-else-if="item.isCheck">
                  <a-checkbox
                    v-model:checked="group[item.key]"
                    @change="changeCheck(gindex, item.key, $event)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </template>
                <!-- 选择模板类型：单选框 -->
                <template v-else>
                  <a-radio-group
                    v-model:value="group[item.key]"
                    @change="changeTemplate(gindex, $event)"
                  >
                    <a-radio value="coin">虚拟币</a-radio>
                    <a-radio value="vip">会员</a-radio>
                    <a-radio value="unlock">全集解锁</a-radio>
                  </a-radio-group>
                </template>
                <!-- 移动按钮 -->
                <template v-if="index === 0">
                  <div style="flex: 1"></div>
                  <!-- 上移 -->
                  <a-button
                    type="link"
                    :disabled="gindex === 0"
                    @click="move(1, gindex)"
                  >
                    上移
                  </a-button>
                  <!-- 下移 -->
                  <a-button
                    type="link"
                    :disabled="gindex === formState.content.length - 1"
                    @click="move(2, gindex)"
                  >
                    下移
                  </a-button>
                </template>
              </div>
            </a-form-item>
          </template>
        </div>
      </a-form-item>
      <!-- 会员购买入口 -->
      <a-form-item
        label="会员购买入口"
        name="is_vip"
        :rules="[{ required: true }]"
      >
        <a-switch
          v-model:checked="formState.is_vip" 
          checked-children="开"
          un-checked-children="关"
        />
      </a-form-item>
      <!-- 视频集锦 -->
      <!-- <a-form-item
        label="视频集锦"
        name="is_video"
        :rules="[{ required: true }]"
      >
        <a-switch
          v-model:checked="formState.is_video" 
          checked-children="开"
          un-checked-children="关"
        />
      </a-form-item> -->
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { RechargeTemplateTypes, TemplateTypes, RechargeTemplateVipCheck } from '@/utils/constantList'
import { frontChargeUdDetail, frontChargeUdEdit } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 复制
let isCopy = ref(false)
// 父组件传进来的参数
let params = ref({})
// 表单
let formState = reactive({
  // 模板名称
  name: undefined,
  // 模版类型
  is_first_charge: 1,
  // 金额类型
  money_type: undefined,
  // 模板样式（4四宫格 6六宫格）
  style_number: 4,
  // 配置
  content: [],
  // 播放页会员购买入口
  is_vip: false,
  // 视频集锦（播放页支付面板上面的推广视频是否展示）
  is_video: false
})
// 编辑状态，需要把数据每条id存起来，点击保存回传给后端
let editDataIDs = ref([])
// 虚拟币、五折卡、会员（params.type）对应的字段 key
const keySetting = {
  // 虚拟币
  'coin': [
    { key: 'type', name: '', isRadio: true },
    { key: 'money', name: '价格', width: '32%', marginRight: '2%' },
    { key: 'coin', name: '立得', width: '32%', marginRight: '2%' },
    { key: 'free_coin', name: '再送', width: '32%', notRequired: true },
    { key: 'is_help_message', name: '辅助语', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'help_message', name: '', bindCheckKey: 'is_help_message', maxlength: 10, width: '82%' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '82%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '82%' },
    { key: 'is_highlight', name: '底色突出', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'is_gesture', name: '手势引导', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'pay_guide', name: '付费强化引导:', isModule: true }
  ],
  // 会员
  'vip': [
    { key: 'type', name: '', isRadio: true },
    { key: 'days', name: '天', maxlength: 9, width: '49%', marginRight: '2%' },
    { key: 'money', name: '价格', maxlength: 7, width: '49%' },
    { key: 'description', name: '辅助语' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '85%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '85%' },
    { key: 'is_highlight', name: '底色突出', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'is_gesture', name: '手势引导', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'pay_guide', name: '付费强化引导:', isModule: true }
  ],
  // 全集解锁
  'unlock': [
    { key: 'type', name: '', isRadio: true },
    { key: 'money', name: '价格', maxlength: 7, width: '49%', marginRight: '2%' },
    { key: 'description', name: '辅助语', width: '49%' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '85%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '85%' },
    { key: 'is_highlight', name: '底色突出', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'is_gesture', name: '手势引导', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'pay_guide', name: '付费强化引导:', isModule: true }
  ]
}
// 默认模板值
const defaultVals = {
  'coin': {
    project_id: Pub.CUSTOM('project'),
    // 模板类型
    type: 'coin',
    // 金额
    money: undefined,
    // 立得
    coin: undefined,
    // 再送
    free_coin: undefined,
    // 辅助语勾选
    is_help_message: false,
    help_message: undefined,
    // 角标勾选
    is_corner_on: false,
    corner_name: undefined,
    // 气泡勾选
    is_bubble_on: false,
    bubble_name: undefined,
    // 底色突出
    is_highlight: false,
    // 底色突出
    is_gesture: false,
    // 付费强化引导
    item: {
      // 首充引导复充
      is_pay_guide: false,
      // 复充类型
      pay_guide_type: undefined,
      // 复充价格
      pay_guide_money: undefined,
      // 折扣弹出频次
      pay_discount_type: undefined,
      // 折扣价格
      pay_discount_money: undefined,
      // iaa 开关
      is_pay_iaa: false
    },
    // 平台
    platform_id: 0
  },
  'vip': {
    project_id: Pub.CUSTOM('project'),
    // 模板类型
    type: 'vip',
    // 天
    days: undefined,
    // 价格
    money: undefined,
    // 辅助语
    description: undefined,
    // 角标勾选
    is_corner_on: false,
    corner_name: undefined,
    // 气泡勾选
    is_bubble_on: false,
    bubble_name: undefined,
    // 底色突出
    is_highlight: false,
    // 底色突出
    is_gesture: false,
    // 付费强化引导
    item: {
      // 首充引导复充
      is_pay_guide: false,
      // 复充类型
      pay_guide_type: undefined,
      // 复充价格
      pay_guide_money: undefined,
      // 折扣弹出频次
      pay_discount_type: undefined,
      // 折扣价格
      pay_discount_money: undefined,
      // iaa 开关
      is_pay_iaa: false
    },
    // 平台
    platform_id: 0
  },
  'unlock': {
    project_id: Pub.CUSTOM('project'),
    // 模板类型
    type: 'unlock',
    // 价格
    money: undefined,
    // 辅助语
    description: undefined,
    // 角标勾选
    is_corner_on: false,
    corner_name: undefined,
    // 气泡勾选
    is_bubble_on: false,
    bubble_name: undefined,
    // 底色突出
    is_highlight: false,
    // 底色突出
    is_gesture: false,
    // 付费强化引导
    item: {
      // 首充引导复充
      is_pay_guide: false,
      // 复充类型
      pay_guide_type: undefined,
      // 复充价格
      pay_guide_money: undefined,
      // 折扣弹出频次
      pay_discount_type: undefined,
      // 折扣价格
      pay_discount_money: undefined,
      // iaa 开关
      is_pay_iaa: false
    },
    // 平台
    platform_id: 0
  }
}

// 打开抽屉
function showDrawer (obj, copy) {
  // 展开
  visible.value = true
  // 参数保存
  params.value = obj
  isCopy.value = copy
  // 编辑
  if (obj.id) {
    // 名称
    formState.name = obj.name
    // 获取配置详情
    getFrontChargeDetail()
  } else {
    // 新增四组模板
    initTemplate(4)
  }
}

// 拦截处理
function handlePayInput (e, group, key, field) {
  // 如果包含空格则将空格删除掉
  group[key][field] = e.target.value.replace(/\s/g,'')
  // 重新校验
  RefForm.value.validate()
}

// 拦截处理
function handleInput (e, group, key, field) {
  // 如果包含空格则将空格删除掉
  group[key] = e.target.value.replace(/\s/g,'')
  // vip && 天数
  if (group.type === 'vip' && key === 'days') {
    // 重新校验
    RefForm.value.validate()
  }
}

// 切换四六宫格
function changeType () {
  initTemplate(formState.style_number)
}

// 初始化模板（默认是虚拟币模板）
function initTemplate (loop, type = 'coin') {
  // 重置
  formState.content = []
  // 表单值初始化
  nextTick(() => {
    for(let i = 0; i < loop; i++) {
      formState.content[i] = Pub.deepClone(defaultVals[type])
    }
  })
}

// 切换虚拟币会员模板
function changeTemplate (gindex, e) {
  const type = e.target.value
  // 对应索引数据重置
  formState.content[gindex] = Pub.deepClone(defaultVals[type])
}

// 上移、下移
function move (type, index) {
  if (type === 1) {
    // 上移
    const up = formState.content[index - 1]
    formState.content[index - 1] = formState.content[index]
    formState.content[index] = up
  } else {
    // 下移
    const next = formState.content[index + 1]
    formState.content[index + 1] = formState.content[index]
    formState.content[index] = next
  }
}

// 重新校验
function changePayCheck () {
  nextTick(() => {
    // 重新触发校验
    RefForm.value.validate()
  })
}

// 切换气泡角标勾选框
function changeCheck (gindex, key, e) {
  // 限制气泡在一个模板中只能勾选一个
  if (key === 'is_bubble_on') {
    const bool = e.target.checked
    if (bool) {
      // 选中时，把其他组选中重置掉
      formState.content.forEach((item, index) => {
        if (index !== gindex) {
          item.is_bubble_on = false
        }
      })
    }
  }
}

// 获取配置详情
function getFrontChargeDetail () {
  isLoading.value = true
  frontChargeUdDetail({
    id: params.value.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 列表
      const list = data.charge_details
      var newList = []
      if (list.length) {
        // 是否属于复制
        if (isCopy.value) {
          // 复制需要剔除多余的字段
          list.forEach(group => {
            const item = Pub.deepClone(defaultVals[group.type])
            const keys = Object.keys(item)
            keys.forEach(key => {
              item[key] = group[key]
            })
            newList.push(item)
          })
        } else {
          // 属于编辑
          newList = list
        }
        // 有数据
        newList.forEach(group => {
          for(let i in group) {
            if (i === 'is_corner_on' || i === 'is_help_message' ||  i === 'is_bubble_on' ||  i === 'is_highlight' ||  i === 'is_gesture') {
              // 处理气泡、角标选中值为布尔值
              group[i] = !!group[i]
            }
          }
          // 初始化付费强化引导
          group.item = group.item || {}
          // 首充引导复充
          group.item.is_pay_guide = group.item.is_pay_guide ? true : false
          // 复充类型
          group.item.pay_guide_type = group.item.pay_guide_type
          // 复充价格
          group.item.pay_guide_money = group.item.pay_guide_money
          // 折扣弹出频次
          group.item.pay_discount_type = group.item.pay_discount_type
          // 折扣价格
          group.item.pay_discount_money = group.item.pay_discount_money
          // iaa 开关
          group.item.is_pay_iaa = group.item.is_pay_iaa ? true : false
        })
        // 赋值
        formState.content = newList
        // 把原始的每条数据id存起来，编辑的时候 id 需要回传给后端
        editDataIDs.value = list.map(item => item.id)
      }
      // 四六宫格
      formState.style_number = data.charge.style_number || 4
      // 模版类型
      formState.is_first_charge = data.charge.is_first_charge
      // 金额类型
      formState.money_type = data.charge.money_type
      // 播放页会员购买入口
      formState.is_vip = data.charge.is_vip ? true : false
      // 视频集锦
      // formState.is_video = data.charge.is_video ? true : false
      // 触发一次校验
      console.log(formState);
      nextTick(() => {
        RefForm.value.validate()
      })
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    formState.content = []
    formState.name = undefined
    formState.money_type = undefined
    editDataIDs.value = []
    visible.value = false
  }
}

// 付费强化引导校验
function validatorPayGuideMoney (rule, value, group) {
  if (group.item.is_pay_guide && group.item.pay_guide_type !== 2) {
    if (!group.money) {
      return Promise.reject('请填写原价')
    } else if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
      return Promise.reject('必须为正数，且最多保留两位小数')
    } else if (Number(value) > Number(group.money)) {
      return Promise.reject('不得大于原价')
    }
  }
  return Promise.resolve()
}
function validatorPayDiscountMoney (rule, value, group) {
  if (group.item.is_pay_discount) {
    if (!group.money) {
      return Promise.reject('请填写原价')
    } else if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
      return Promise.reject('必须为正数，且最多保留两位小数')
    } else if (Number(value) > Number(group.money)) {
      return Promise.reject('不得大于原价')
    }
  }
  return Promise.resolve()
}

// 表单校验
function validator (rule, value, group) {
  // vip && 金额
  if (group.type === 'vip' && rule.field.includes('money')) {
    if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
      return Promise.reject('必须为正数，且最多保留两位小数')
    } else if (group.days > 0) {
      const info = RechargeTemplateVipCheck.find(item => {
        return (item.min == -1 || group.days >= item.min) && (item.max == -1 || group.days <= item.max)
      })
      const isOK = ((value * 1000) / group.days) >= (info.num * 1000)
      if (isOK) {
        return Promise.resolve()
      } else {
        return Promise.reject('价格过低，请重新设置')
        // if (info.max - info.min === 0) {
        //   return Promise.reject(`${info.min} 天，日均价不得小于 ${info.num}`)
        // } else {
        //   return Promise.reject(`${info.min} - ${info.max} 天，日均价不得小于 ${info.num}`)
        // }
      }
    }
    return Promise.resolve()
  }
  // vip && 天数
  else if (group.type === 'vip' && rule.field.includes('days')) {
    if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
  // 再送（非必填）
  else if (rule.field.includes('free_coin')) {
    if (!Pub.REG_IS_POSITIVE_INTEGER(value) && value !== undefined && value !== '') {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
  // 立得、天数
  else if (rule.field.includes('coin') || rule.field.includes('days')) {
    if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
  // 价格
  else if (rule.field.includes('money')) {
    if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
      return Promise.reject('必须为正数，且最多保留两位小数')
    }
    return Promise.resolve()
  }
  // 辅助语、角标、气泡
  else if (rule.field.includes('description') || rule.field.includes('corner_name') || rule.field.includes('bubble_name') || rule.field.includes('help_message')) {
    if (!value && rule.required) {
      return Promise.reject('请输入')
    }
    return Promise.resolve()
  }
  return Promise.resolve()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 复制一份数据处理选中值为 0 、1
    let copyData = Pub.deepClone(formState.content)
    copyData.forEach((group, idx) => {
      for(let i in group) {
        if (i === 'is_corner_on' || i === 'is_help_message' ||  i === 'is_bubble_on' ||  i === 'is_highlight' ||  i === 'is_gesture') {
          // 处理气泡、角标选中值为布尔值
          group[i] = group[i] ? 1 : 0
        }
        if ((i === 'bubble_name' || i === 'corner_name' || i === 'help_message') && !group[i]) {
          // 处理气泡、角标内容（undefined会被过滤）
          group[i] = ''
        }
      }
      // 首充引导复充
      group.item.is_pay_guide = group.item.is_pay_guide ? 1 : 0
      // iaa 开关
      group.item.is_pay_iaa = group.item.is_pay_iaa ? 1 : 0
      // 编辑时，把对应索引的原有id值插进去
      group['id'] = isCopy.value ? undefined : (editDataIDs.value[idx] || undefined)
    })
    const query = {
      id: isCopy.value ? undefined : (params.value.id || undefined),
      // 自定义模板，无平台值
      platform_id: 0,
      // 自定义虚拟币
      type: 'ud_coin',
      // 名称
      name: formState.name,
      // 宫格
      style_number: formState.style_number,
      // 列表
      content: copyData,
      // 金额类型
      money_type: formState.money_type,
      // 模版类型
      is_first_charge: formState.is_first_charge,
      // 播放页会员购买入口
      is_vip: formState.is_vip ? 1 : 0,
      // 视频集锦
      // is_video: formState.is_video ? 1 : 0
    }
    frontChargeUdEdit(query).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.grow {
  display: flex;
}
.form-item-clear {
  margin-bottom: 0;
}
.data-list {
  display: flex;
  flex-wrap: wrap;
}
.play-item {
  margin-bottom: 16px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
.module-title {
  font-weight: bold;
  padding-bottom: 20px;
}
.module-panel {
  display: flex;
  padding-top: 14px;
  padding-left: 26px;
}
</style>